import {i18nEnvironment} from "./i18n/i18n-environment.prod";

export const environment = {
  production: true,
  userServiceBackendAddress: 'https://my.bucherunipektin.com/backend/user-service',
  machineryBackendAddress: 'https://my.bucherunipektin.com/backend/machinery',
  ticketSystemBackendAddress: 'https://my.bucherunipektin.com/backend/ticket-system',
  vncProxyBackendAddress: 'https://my.bucherunipektin.com/backend/vnc-proxy',
  contentManagementBackendAddress: 'https://my.bucherunipektin.com/backend/content-management',
  orderServiceBackendAddress: 'https://my.bucherunipektin.com/backend/order-service',
  vncWebsocketAddress: 'wss://my.bucherunipektin.com/backend/vnc-proxy/vnc/',
  adminFrontendAddress: i18nEnvironment.adminFrontendAddress,
  ticketSystemFrontendAddress: i18nEnvironment.ticketSystemFrontendAddress,
  iotFrontendAddress: i18nEnvironment.iotFrontendAddress,
  machineryFrontendAddress: i18nEnvironment.machineryFrontendAddress,
  frontendBaseAddresses: {
    en_US: 'https://my.bucherunipektin.com',
    de_DE: 'https://my.bucherunipektin.com/de'
  },
  moduleFederationManifestFilePath: i18nEnvironment.moduleFederationManifestFilePath,
  googleAnalyticsId: 'G-VYT2SJDM42',
  isSingleSignOnEnabled: false,
  applicationInsights: {
    instrumentationKey: '125bde60-93a1-455b-9633-dddd81d7cf28'
  },
  isNewsEnabled: false
};
